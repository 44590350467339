window.addEventListener(
  "DOMContentLoaded",
  (event) => {
    let printBtn = document.getElementById("printBtn");
   if (printBtn) { 
       printBtn.addEventListener("click", (e) => {
         let pdfFrame = window.frames["pdf"];
         pdfFrame.focus();
         pdfFrame.print();
       });
   }
  }
);
